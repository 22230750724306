<template>
  <div class="page">
    <div class="banner"></div>
    <div class="home-swiper">
      <div class="swiper-title">
        <div class="title1">全部分类</div>
        <div class="title2">ALL CATEGORIES</div>
      </div>
      <div class="swiper">
        <div class="container">
          <div  class="leftSwi" @click="handleSwiper('left')">
            <div class="left"></div>
          </div>
          <div class="main">
            <!-- <ul ref="ul">
              <li> -->
                <div class="list"  v-if="index==0">
                  <div>
                    <img src="../../../public/img/item1-top.png" alt="" />
                  </div>
                  <div>
                    <img src="../../../public/img/item1-bottom.png" alt="" />
                  </div>
                </div>
              <!-- </li>
              <li> -->
                <div class="list" v-if="index==1">
                  <div>
                    <img src="../../../public/img/item2-top.png" alt="" />
                  </div>
                  <div>
                    <img src="../../../public/img/item2-bottom.png" alt="" />
                  </div>
                </div>
              <!-- </li>
              <li> -->
                <div class="list" v-if="index==2">
                  <div>
                    <img src="../../../public/img/item3-top.png" alt="" />
                  </div>
                  <div>
                    <img src="../../../public/img/item3-bottom.png" alt="" />
                  </div>
                </div>
              <!-- </li>
            </ul> -->
              <!-- 左右滑动为了实现一种无缝衔接需要在末尾复制一份第一张图片,渐变式不需要这张图可以去掉 -->
          </div>
          <div class="rightSwi" @click="handleSwiper('right')">
            <div class="right"></div>
          </div>
        </div>
        <!-- TODO 指示器 -->
        <div class="indicator">
          <div
            :style="index == 0 ? 'background: #003D8E;' : ''"
            style="margin-right: 30px"
          ></div>
          <div
            :style="index == 1 ? 'background: #003D8E;' : ''"
            style="margin-right: 30px"
          ></div>
          <div :style="index == 2 ? 'background: #003D8E;' : ''"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      number:0,
    };
  },
  methods: {
    handleSwiper(type) {
      if(type == "left"){
        if(this.number>0){
          this.number--
        }else{
        this.number-=2
        }
        this.index= Math.abs(this.number)  % 3
        // console.log("zuohua",this.index,this.number)
      }else{
        if(this.number>=0){
          this.number++
        }else{
        this.number+=2
        }
        this.index=Math.abs(this.number) % 3
        // console.log("youhua",this.index,this.number)
      }
      // let ul = this.$refs.ul;
      // if (type == "left") {
      //   if (this.index == 0) {
      //     console.log("小于1");
      //     this.index = 2;
      //     ul.style.left = `${-1400 * 2}px`;
      //   } else {
      //     this.index--;
      //     ul.style.left = `${-1400 * this.index}px`;
      //   }
      // } else {
      //   if (this.index == 2) {
      //     // ul.style.left = "0";
      //     ul.style.left = `${-1400 * this.index}px`;
      //     this.index = 0;
      //   } else {
      //     this.index++;
      //     ul.style.left = `${-1400 * this.index}px`;
      //   }
      // }
      // console.log("ul", ul);
      console.log("index ==>", this.index);
      //   ul.style.left = "-1100px";
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  .banner {
    width: 100%;
    height: 700px;
    background: url("../../../public/img/category.png") no-repeat;
    background-size: cover;
  }
  .title {
    width: 128px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #333333;
    margin: 50px auto;
  }

  .home-tags {
    width: 100%;
    height: 500px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tags-title {
      margin-top: 50px;
      .title1 {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 45px;
        text-align: center;
        color: #333333;
      }
      .title2 {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #999999;
        margin-top: 10px;
      }
    }
    .tag-items {
      display: flex;
      .item {
        width: 180px;
        height: 225px;
        margin: 0 42.5px;
        margin-top: 70px;
      }
      .bg1 {
        background: url("../../../public/img/search_icon.png");
        background-size: cover;
      }
      .bg2 {
        background: url("../../../public/img/recommend_icon.png");
        background-size: cover;
      }
      .bg3 {
        background: url("../../../public/img/safeguard_icon.png");
        background-size: cover;
      }
      .bg4 {
        background: url("../../../public/img/safeguard_icon.png");
        background-size: cover;
      }
      .bg5 {
        background: url("../../../public/img/data_icon.png");
        background-size: cover;
      }
    }
  }
  .home-swiper {
    width: 100%;
    height: 1097px;
    background-color: #fafafa;
    overflow: hidden;
    .swiper-title {
      margin-top: 50px;
      .title1 {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 45px;
        text-align: center;
        color: #333333;
      }
      .title2 {
        margin-top: 10px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #999999;
      }
    }
    .swiper {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .container {
        // width: 1242px;
        display: flex;
        align-items: center;
        .leftSwi{
          width: 70px;
          height: 70px;
          border-radius: 70px;
          background: #F0F0F0;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .left {
          width: 19px;
          height: 32.5px;
          background: url("../../../public/img/left_icon.png");
          background-size: cover;
          cursor: pointer;
        }
        .main {
          width: 1300px;
          margin: 0 71px;
          height: 812px;
          overflow: hidden;
          // position: relative;
          /* 图片基础样式 */
          .list {
            animation: 2s linear;
            height: 812px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            img {
              width: 1300px; /* 保证图片尺寸统一 */
              height: 381px;
            }
          }
          ul {
            position: absolute;
            top: 0;
            width: 400%;
            padding: 0;
            margin: 0;
            animation: 2s linear;
            .list {
              height: 812px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              img {
                width: 1300px; /* 保证图片尺寸统一 */
                height: 381px;
              }
            }
          }
          li {
            list-style: none;
            float: left;
            margin-right: 50px;
          }
        }
        .rightSwi{
          width: 70px;
          height: 70px;
          border-radius: 70px;
          background: #F0F0F0;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .right {
          width: 19px;
          height: 32.5px;
          background: url("../../../public/img/right_icon.png");
          background-size: cover;
          cursor: pointer;
        }
      }
      .indicator {
        height: 10px;
        display: flex;
        margin-top: 50px;
        div {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #d9d9d9;
        }
      }
    }
  }
}
</style>
